<template>
  <div>
    <base-navbar :backIcon="true" />
    <h1>Highscores</h1>
    <div :class="$style.item" v-for="(item, index) in highscores" :key="index">
      <span>{{ index + 1 }}</span>
      <span>
        {{ item.firstname }} {{ item.middlename }} {{ item.lastname }}
      </span>
      <span>{{ item.score }}</span>
    </div>
  </div>
</template>

<script>
import BaseNavbar from '@/components/BaseNavbar';

export default {
  name: 'Highscores',
  components: {
    BaseNavbar,
  },
  computed: {
    highscores() {
      const scores = this.$store.state.highscores;
      if (scores) {
        const filtered = scores.filter((item) => item.score > 0);
        return filtered;
      } else return scores;
    },
  },
  methods: {
    back() {
      this.$router.push('/');
    },
  },
  mounted() {
    this.$store.dispatch('fetchHighscores');
  },
};
</script>

<style lang="scss" module>
.item {
  background: $bg;
  color: $primary;
  padding: $spacing-small $spacing-default;
  font-size: 1rem;
  border: $primary;
  border-style: solid;
  border-width: 2px;
  font-family: $font-family;
  border-radius: 0;
  margin: $spacing-default;
  display: flex;
  justify-content: space-between;

  &:first-of-type {
    background: chartreuse;
  }

  &:focus {
    border-radius: 0;
  }
}
</style>
